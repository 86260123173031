<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}} -
          <span class="blink">ตั้งค่า</span>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            select="value"
            v-model="selected"
            placeholder="เลือกประเภท...">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <router-view></router-view>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}-ตั้งค่า`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DocConfig`,
      rFields: ['selected'],
      options: [
        {text: 'รายงานกลุ่ม', value: 'ReportGroup'},
        {text: 'ตั้งค่ากระดาษรายการสินค้า', value: 'PrintItemPdf'},
        {text: 'ตั้งค่ากระดาษงานบัญชี', value: 'PrintLedgerPdf'},
        {text: 'คู่มือการใช้งาน', value: 'UserManual'}
      ],
      selected: null
    }
  },
  watch: {
    selected (value) {
      this.setRetaining()

      const comp = this.options.find(v => v.value === value)?.value || ''

      const docConfigRoute = `Doc${this.$form.capitalize(this.docType)}DocConfig${comp}`
      if (this.isRetainingLoaded && this.$route.name !== docConfigRoute) {
        this.$router.push({name: docConfigRoute})
      }
    }
  }
}
</script>

<style lang="css">
</style>
